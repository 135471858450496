import React from 'react'

import '@styleGlobals/globals.css'

import { LayoutProps } from './LayoutProps'

const Layout = ({ children }: LayoutProps) => {
  return (
    <main className="layout" id="layout">
      {children}
    </main>
  )
}

export default Layout;
